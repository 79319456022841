<template>
  <div class="directory-news-list__wrapper">
    <i class="directory-news-list__delimiter" />
    <h4 class="directory-news-list__title">From Our Directory:</h4>
    <i class="directory-news-list__delimiter" />
    <ul class="directory-news-list__list">
      <li
        v-for="article in articles"
        :key="article.Id"
        class="directory-news-list__list-item"
      >
        <a-dots-responsive
          :text="article.Topic"
          tag="h4"
          :link-props="{ to: generateFmDirArticleUrl(article) }"
          :settings="$options.consts.topicSettings"
          class="directory-news-list__article-topic"
        />
        <a-link
          :to="linkToCompany(article)"
          class="directory-news-list__company-name"
        >
          {{ article.CompanyName }}
        </a-link>
      </li>
    </ul>
    <i class="directory-news-list__delimiter" />
  </div>
</template>
<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import mixins from '@/utils/mixins'
import ADotsResponsive, { configADots } from 'shared/ADotsResponsive'
import scss from '@/utils/scss'
const { breakpoints } = scss

export default {
  name: 'DirectoryNewsList',
  mixins: [serverCacheKey, mixins.urlGenerators],
  components: { ADotsResponsive },
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  },
  consts: {
    topicSettings: {
      [breakpoints.aboveDesktopXl]: configADots(4, 18, true),
      [breakpoints.desktopXl]: configADots(3, 18, true),
      [breakpoints.desktopLg]: configADots(4, 18, true),
      [breakpoints.desktopMd]: configADots(5, 18, true),
      [breakpoints.tablet]: configADots(2, 18, true),
      [breakpoints.mobile]: configADots(3, 18, true)
    }
  },
  methods: {
    linkToCompany(article) {
      const { CompanySlug: Slug, CategorySlug } = article
      return this.generateCompanyUrl({ Slug, CategorySlug })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/global/mixins/figures';
@import '~assets/scss/local/mixins/grid';

.directory-news-list__wrapper {
  .directory-news-list__title {
    $font-size: (
      $above-desktop-xl: 22px,
      $desktop-md: 17px,
      $tablet: 25px
    );
    @include setStyleByBreakpoint('font-size', $font-size);

    line-height: 28px;
  }

  .directory-news-list__delimiter {
    @include delimiter(#d6d3d3, $mt: 13px, $mb: 13px);
  }

  .directory-news-list__list {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(2, minmax(0, 100%))
      ),
      $rowSetup: (
        $above-desktop-xl: repeat(1, minmax(0, auto))
      ),
      $columnGapSetup: (
        $above-desktop-xl: 20px
      )
    );

    @include gridBuilder($grid-setup);
  }

  .directory-news-list__article-topic {
    display: block;

    $font-size: (
      $above-desktop-xl: 16px,
      $desktop-md: 13px,
      $tablet: 14px
    );
    @include setStyleByBreakpoint('font-size', $font-size);
  }

  .directory-news-list__company-name {
    $font-size: (
      $above-desktop-xl: 16px,
      $desktop-md: 14px
    );
    @include setStyleByBreakpoint('font-size', $font-size);

    display: block;
    margin-top: 15px;
    line-height: 18px;
    font-weight: 600;
  }
}
</style>
